import { APIFilter } from '@/utils/api'

export default {
  async selectSobreDocumento (Vue, filter, search, sorter, page, idsobre) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
      .addExact('idsobre', idsobre)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.sobreDocumento.searchFilter(search))
    }
    const resp = await Vue.$api.call(
      'sobreDocumento.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectSobreDocumentoRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idsobre_documento', pks)
    const resp = await Vue.$api.call('sobreDocumento.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
